import "./src/styles/global.css";
import "./src/styles/menu.scss";

import "@fontsource/nunito-sans/200.css";
import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";

export const onInitialClientRender = () => {
  // wait to init GTM until after React has hydrated in this lifecycle
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
};
